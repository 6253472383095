import React, { useState, useEffect } from "react";
import "../Styles/CarouselComponent.css";
// import slide1 from "../Images/Carousel1.png";
// import slide2 from "../Images/Carousel2.jpg";
// import slide3 from "../Images/Carousel3.jpg";

import ACslide1 from "../Images/ACSlide1.jpg";
import ACslide2 from "../Images/ACSlide2.jpg";
import ACslide3 from "../Images/ACSlide3.jpg";
import ACslide4 from "../Images/ACSlide4.jpg";
import ACslide5 from "../Images/ACSlide5.jpg";

// import ACiconCarousel from "../Images/ACiconCarousel.png";
// import NewACicon from "../Images/NewLogo.png";
import NewACWhiteIcon from "../Images/NewWhiteLogo.png";
// import ACicon2Carousel from "../Images/ACicon2Carousel.png";

const CarouselComponent = () => {
  const images = [
    // slide1,
    ACslide1,
    // slide2,
    ACslide5,
    // slide3,
    ACslide2,
    ACslide3,
    ACslide4,
  ];
  const alt_texts = [
    "People kayaking",
    "Gainesville downtown",
    "Children planting trees",
    "Dancers performing",
    "Ben Hill Griffin stadium",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  // Automatically move to the next slide every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      // handleNext();
      setCurrentIndex((currentIndex + 1) % images.length);
    }, 5000); // 3000 milliseconds = 3 seconds

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [currentIndex]); // Add currentIndex as a dependency

  return (
    <div className="carousel">
      <img src={images[currentIndex]} alt={alt_texts[currentIndex]} />
      {/* AC icon */}
      <div className="icon-container-top">
        <img
          src={NewACWhiteIcon}
          alt="Discover 200 Icon"
          className="carousel-icon-top"
        />
      </div>

      {/* Dots for navigation */}
      <div className="dots-container">
        {images.map((_, index) => (
          <div
            key={index}
            className={`dot ${currentIndex === index ? "active" : ""}`}
            onClick={() => goToSlide(index)} // Navigate to the selected slide
          ></div>
        ))}
      </div>

      {/* AC icon 2 */}
      <div className="icon-container-bottom">
        {/* <img
          src={ACicon2Carousel}
          alt="Icon"
          className="carousel-icon-bottom"
        /> */}
        <h1 className="carousel-text-bottom">
          200 Years <br /> In The Making
        </h1>
      </div>
    </div>
  );
};

export default CarouselComponent;
