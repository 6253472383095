import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import CarouselComponent from "../Components/CarouselComponent";
import Menu from "../Components/Menu";
import SetSchedule2 from "./SetSchedule2";

import "../Styles/HomePage.css";

import updatedEventPoster from "../Images/updated-200th-final-poster.jpg";
import newSideImage from "../Images/final-side-hero.png";
import instaIcon from "../Images/instaIcon.png";
import fbIcon from "../Images/fbIcon.png";
import xIcon from "../Images/twitterIcon.png";
import ytIcon from "../Images/ytIcon.png";
import flickrIcon from "../Images/flickrIcon.png";
import crWide from "../Images/chuckregan-wide.jpg";
import cliffLR from "../Images/cliffdorsey_LR.jpg";
import jakeLR from "../Images/littlejake_LR.jpg";
import kingLR from "../Images/kingfish_LR.jpg";
import proclamation from "../Assets/proclamation-web.pdf";

const HomePage = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  return (
    <div className="HomePage">
      {/* Carousel and logos */}
      <div className="header">
        <div className="side-image">
          <img src={newSideImage} alt="Carousel Side Graphic" />
        </div>
        <div className="carousel-wrapper">
          <CarouselComponent />
          <Menu />
        </div>
      </div>
      <main id="main">
        {/* Section1 gray background including bands and map sections */}
        <div class="gray-background full-page-padding">
          <div>
            <div
              className="blue-text h1-heading"
              style={{
                lineHeight: "0.9",
                textAlign: "center",
              }}
            >
              Discover 200: <br />A Bicentennial Celebration
            </div>
            {/* Bands section begin*/}
            <div class="band-gallery">
              <div class="band-image">
                <a
                  href="https://www.christonekingfishingram.com/biography/"
                  class="band-link"
                >
                  <img
                    src={kingLR}
                    alt="Christone “Kingfish”
                  Ingram"
                  />
                  <p>
                    Christone “Kingfish”
                    <br /> Ingram
                  </p>
                </a>
              </div>
              <div class="band-image">
                <a href="https://chuckraganmusic.com/bio/" class="band-link">
                  <img src={crWide} alt="Chuck Ragan & The Camaraderie" />
                  <p>
                    Chuck Ragan & <br /> The Camaraderie
                  </p>
                </a>
              </div>
              <div class="band-image">
                <a href="https://www.littlejakemitchell.com/" class="band-link">
                  <img src={jakeLR} alt=" Little Jake & The Soul Searchers" />
                  <p>
                    Little Jake & <br /> The Soul Searchers
                  </p>
                </a>
              </div>
              <div class="band-image">
                <a href=" https://www.cliffdorsey.com/" class="band-link">
                  <img src={cliffLR} alt="The Cliff Dorsey Band" />
                  <p>The Cliff Dorsey Band</p>
                </a>
              </div>
            </div>
            {/* Bands section end*/}
            {/* event poster section begin*/}
            <div class="section-container gap50 poster-section">
              <div
                class="small-content blue-text"
                style={{ alignSelf: "flex-start" }}
              >
                <div
                  class="h3-heading"
                  style={{
                    fontWeight: "bold",
                    lineHeight: "0.9",
                    textAlign: "left",
                  }}
                >
                  Saturday, Jan. 11, 2025 <br />
                  from 1-10 p.m.
                </div>
                <p class="text-para">
                  Alachua County invites you to join us for Discover 200 – A
                  Bicentennial Celebration, a free, family-friendly event. This
                  event marks the start of a yearlong celebration honoring the
                  founding of Alachua County.
                  <br /> <br />
                  The event includes music on two stages at Depot Park and
                  Heartwood Soundstage. There will be food trucks, free face
                  paintings and balloon animals for kids, a strolling magician,
                  historical and county service exhibits, UF mascots Albert and
                  Alberta and much more.
                  <br />
                  <br /> We look forward to celebrating Alachua County’s
                  birthday with you.
                  <br /> For set times and more information,{" "}
                  <Popup
                    trigger={
                      <button
                        class="popup-buttons"
                        aria-describedby="set schedule popup"
                      >
                        <u>click here</u>
                      </button>
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div className="modal">
                        <div>
                          <button class="popup-buttons" onClick={() => close()}>
                            Close
                          </button>
                        </div>

                        <SetSchedule2 />
                      </div>
                    )}
                  </Popup>
                </p>
              </div>
              <div class="large-content event-poster-container">
                <img
                  class="event-poster"
                  src={updatedEventPoster}
                  alt="Discoover 200 Event Poster"
                />
                <div
                  class="blue-text h2-heading"
                  style={{
                    textAlign: "center",
                  }}
                ></div>
              </div>
            </div>
            {/* event poster section end*/}
          </div>
        </div>
        {/* Section2 black background Only Proclamation*/}
        <div class="black-background2 full-page-padding">
          <div>
            <div class="section-container gap50">
              <div class="large-content">
                <div
                  class="text-para"
                  style={{
                    color: "white",
                    display: "grid",
                  }}
                >
                  <span
                    class="proclamation-heading"
                    style={{
                      fontFamily: "Timberline",
                      justifySelf: "center",
                      textAlign: "center",
                      color: "goldenrod",
                    }}
                  >
                    Alachua County Florida A Proclamation
                  </span>
                  <span
                    class="proclamation-2nd-heading"
                    style={{
                      fontFamily: "AGPbold",
                      justifySelf: "center",
                      textAlign: "center",
                      paddingTop: "30px",
                    }}
                  >
                    Proclaiming 2025 as “Discover 200 Year - A Bicentennial
                    Celebration” in Alachua County, Florida.
                  </span>
                  <>
                    <Swiper
                      pagination={pagination}
                      modules={[Pagination]}
                      className="mySwiper"
                    >
                      <SwiperSlide>
                        <ul
                          style={{
                            padding: "0",
                            paddingBottom: "20px",
                            listStyleType: "none",
                          }}
                        >
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; Alachua County’s lands have been nurtured
                              and stewarded by Indigenous peoples long before
                              recorded history, including the Timucua,
                              Seminoles, and other tribes whose presence and
                              contributions remain vital to the area’s cultural
                              heritage; and
                            </p>
                          </li>
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; The name “Alachua,” derived from the
                              Timucua and means “big jug,” paying tribute to the
                              region’s unique sinkhole geology and the Alachua
                              prairie settlement; and
                            </p>
                          </li>
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; In 1539, Spanish explorer Hernando de Soto
                              passed through the area, marking the beginning of
                              the European contact that profoundly impacted
                              indigenous peoples; and
                            </p>
                          </li>
                        </ul>
                      </SwiperSlide>
                      <SwiperSlide>
                        <ul
                          style={{
                            padding: "0",
                            paddingBottom: "20px",
                            listStyleType: "none",
                          }}
                        >
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; Alachua County was officially established
                              on December 29, 1824, carved from what was the
                              frontier of Spanish Florida, encompassing lands
                              stretching from the Georgia border to the Gulf of
                              Mexico to Port Charlotte, making it one of
                              Florida’s first counties; and{" "}
                            </p>
                          </li>
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; The contributions of African Americans have
                              been integral to the history and development of
                              Alachua County, from the enslaved people who built
                              the foundations of its agricultural economy to the
                              Reconstruction Era when African Americans became
                              leaders in culture, politics, education and
                              justice, to the civil rights movement with local
                              leaders and activists championing the cause of
                              equality; and
                            </p>
                          </li>
                        </ul>
                      </SwiperSlide>
                      <SwiperSlide>
                        <ul
                          style={{
                            padding: "0",
                            paddingBottom: "20px",
                            listStyleType: "none",
                          }}
                        >
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; Alachua County continues its Truth &
                              Reconciliation process to acknowledge and heal
                              from its legacy of racial injustice, paving the
                              way for a future rooted in equity, inclusion, and
                              mutual respect; and
                            </p>
                          </li>
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; Alachua County’s cultural and ethnic
                              diversity enriches its communities, fostering
                              understanding, creativity, and shared prosperity;
                              and
                            </p>
                          </li>
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; Alachua County has a rich history of
                              economic growth rooted in agriculture, railroads,
                              and education; and
                            </p>
                          </li>
                        </ul>
                      </SwiperSlide>
                      <SwiperSlide>
                        <ul
                          style={{
                            padding: "0",
                            paddingBottom: "20px",
                            listStyleType: "none",
                          }}
                        >
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; The University of Florida and Santa Fe
                              College have played a transformative role in
                              driving and diversifying the economy through
                              research, technology, and workforce development;
                              and
                            </p>
                          </li>
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              {" "}
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; Alachua County is as a hub for biotech,
                              life sciences, and sustainable innovation,
                              attracting national and global investment, and{" "}
                            </p>
                          </li>
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; Alachua County is renowned for its unique
                              blend of natural beauty, including nature
                              preserves, parks, springs, river and wildlife
                              habitats; and Alachua County is committed to
                              preserving its natural resources as a leader in
                              land conservation, sustainability initiatives, and
                              climate resilience; and{" "}
                            </p>
                          </li>
                        </ul>
                      </SwiperSlide>
                      <SwiperSlide>
                        <ul
                          style={{
                            padding: "0",
                            paddingBottom: "20px",
                            listStyleType: "none",
                          }}
                        >
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; Alachua County is home to an extraordinary
                              community of artists and artistic offerings,
                              including theatre productions, dance performances,
                              music, visual arts, and writers, all of which
                              enrich the cultural fabric of the region and
                              inspire creativity for residents and visitors
                              alike; and
                            </p>
                          </li>
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; Alachua County is a premier destination for
                              sports and recreation, offering world-class
                              collegiate athletics, a wide range of youth and
                              adult sports leagues, and state-of-the-art
                              facilities for sports activities, making it a hub
                              for athletes and enthusiasts from around the
                              globe; and{" "}
                            </p>
                          </li>
                        </ul>
                      </SwiperSlide>
                      <SwiperSlide>
                        <ul
                          style={{
                            padding: "0",
                            paddingBottom: "20px",
                            listStyleType: "none",
                          }}
                        >
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Whereas,
                              </span>
                              &emsp; Alachua County is made up of a large
                              unincorporated area and nine distinct cities that
                              include our county seat Gainesville, Archer,
                              Alachua, Micanopy, Waldo, Hawthorne, High Springs,
                              Newberry and La Crosse, encompassing 876 square
                              miles and home to nearly 290,000 residents.
                            </p>
                          </li>
                          <li>
                            <p style={{ marginTop: "0", marginBottom: "10px" }}>
                              <span
                                class="whereas"
                                style={{
                                  fontFamily: "Enchanted",
                                  color: "goldenrod",
                                }}
                              >
                                Now, therefore,
                              </span>
                              &emsp; through the authority vested in me by the
                              Board of County Commissioners of Alachua County,
                              Florida, I do hereby proclaim 2025, as “Discover
                              200 Year – A Bicentennial Celebration” in Alachua
                              County, Florida.{" "}
                            </p>
                          </li>
                          <li>
                            <p>
                              Duly proclaimed this 10th day of December, A.D.,
                              2024.
                            </p>
                          </li>
                        </ul>
                      </SwiperSlide>
                    </Swiper>
                  </>
                  <span
                    class="proclamation-button"
                    style={{
                      fontFamily: "AGPbold",
                      justifySelf: "center",
                      textAlign: "center",
                      border: "4px",
                      borderColor: "goldenrod",
                      borderStyle: "solid",
                      padding: "10px",
                    }}
                  >
                    &emsp;
                    <a
                      style={{ color: "goldenrod" }}
                      href={proclamation}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click to view the entire Proclamation
                    </a>
                    &emsp;
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section3 white background including social media posts*/}
        <div class="white-background mobile-padding">
          <div
            className="blue-text h2-heading"
            style={{
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            #Discover200
          </div>
          <iframe
            class="social-wall"
            src="https://widget.tagembed.com/2141741"
            alt="Social Media Wall"
            title="Social Media Wall"
          ></iframe>
        </div>
        {/* Section4 blue background including disclaimer*/}
        <div class="blue-background full-page-padding">
          <div>
            <div class="social-media-icons-container">
              <div class="social-media-icons">
                <a href="https://x.com/alachuacounty">
                  <img class="smi-images" src={xIcon} alt="X Icon" />
                </a>
                <a href="https://www.youtube.com/alachuacounty">
                  <img src={ytIcon} alt="Youtube Icon" class="smi-images" />
                </a>
                <a href="https://www.facebook.com/AlachuaCounty/">
                  <img class="smi-images" src={fbIcon} alt="Facebook Icon" />
                </a>
                <a href="https://www.instagram.com/alachuacounty/">
                  <img
                    class="smi-images"
                    src={instaIcon}
                    alt="Instagram Icon"
                  />
                </a>
                <a href="https://www.flickr.com/photos/66143513@N03/">
                  <img class="smi-images" src={flickrIcon} alt="Flickr Icon" />
                </a>
              </div>
            </div>
            <div style={{ color: "white", textAlign: "left" }}>
              <div class="h3-heading">Disclaimer</div>
              <div class="disclaimer-text">
                <p>
                  Alachua County aims to continually improve the accessibility
                  and usability of its website. If you are an individual with a
                  disability and you experience difficulty or require assistance
                  or accommodation in using our website, please contact the
                  Alachua County ADA Coordinator at ADA@alachuacounty.us or call
                  the Alachua County Equal Opportunity Office at 352-374-5275;
                  TDD/TTY Users please call 711 Florida Relay Service.
                  <div style={{ textAlign: "center" }}>
                    <a
                      href="https://alachuacounty.us/Depts/EO/Pages/Website-Accessibility.aspx"
                      style={{ color: "white" }}
                    >
                      View Alachua County's Website Accessibility Policy And
                      Procedures
                    </a>
                  </div>
                </p>
                <p>
                  If you have a disability and need an accommodation in order to
                  participate in a County program, service or public meeting,
                  please contact the Equal Opportunity Office at 352-374-5275 at
                  least 2 business days prior to the event. TDD users, please
                  call 711 (Florida Relay Service).
                </p>
                <p>
                  To make a public records request, please contact the Public
                  Records Custodian at (352) 264-6906 or{" "}
                  <a
                    href="mailto:publicrecordsrequest@alachuacounty.us"
                    style={{ color: "white" }}
                  >
                    publicrecordsrequest@alachuacounty.us.
                  </a>{" "}
                  Please visit the{" "}
                  <a
                    href="/Depts/Communications/Pages/PublicRecordsRequest.aspx"
                    style={{ color: "white" }}
                  >
                    Public Records Request
                  </a>{" "}
                  webpage for more information.
                </p>
                <p>
                  This website is a public service. Please read the Legal
                  Disclaimer. Website designed and engineered by Alachua County
                  ITS, Applications Division Version 2014 (v1.0) Under Florida
                  law (Statute 119.011), all information, including e-mail,
                  written letters, documents and phone messages, sent to the
                  Alachua County Board of County Commissioners is subject to
                  Public Records law. This includes the sender's e-mail address,
                  home address or phone number if shown in the message, the
                  content of the message and any associated attachments to the
                  mail. Also please be aware that electronic correspondence
                  (e-mail) is made available on the Commission's public archive
                  site immediately upon being sent. Instead, contact Alachua
                  County Offices by phone or in writing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default HomePage;
