import React from "react";
// import schCover from "../Images/sch-head.jpg";
// import schBannertr from "../Images/sch-banner-tr.png";
// import schBanner from "../Images/sch-popup-banner.png";
import scheduleBanner from "../Images/scheduleBanner.png";

import "../Styles/SetSchedule2.css";

const SetSchedule2 = () => {
  return (
    <div>
      <header className="sch-header">
        <img src={scheduleBanner} alt="Events Banner" className="sch-banner" />
      </header>
      <div className="sch-content">
        <h2 style={{ margin: "0" }} class="gold-text">
          JAN 11, 2025 • GAINESVILLE , FL
        </h2>
        <h1 style={{ margin: "0" }}>SET TIMES</h1>
        <h2 style={{ margin: "0" }}>VENUES OPEN AT 1 P M</h2>
        <table class="spaced-table table-content">
          <tr class="gold-text">
            <th>
              <h2 style={{ margin: "0" }}>Depot Park</h2>
            </th>
            <th>
              <h2 style={{ margin: "0" }}>Heartwood Soundstage</h2>
            </th>
          </tr>
          <tr>
            <td>
              Little Jake & The Soul Searchers –
              <span class="gold-text"> 2 p.m.</span>
            </td>
            <td>
              Purple Kloud – <span class="gold-text"> 1:30 p.m.</span>
            </td>
          </tr>
          <tr>
            <td>
              The Savants –<span class="gold-text"> 3:10 p.m.</span>
            </td>
            <td>
              Nancy Luca –<span class="gold-text"> 2:20 p.m.</span>
            </td>
          </tr>
          <tr>
            <td>
              Dikembe – <span class="gold-text"> 4:20 p.m.</span>
            </td>
            <td>
              Noah Moses & The Flood – <span class="gold-text"> 3:20 p.m.</span>
            </td>
          </tr>
          <tr>
            <td>
              Cliff Dorsey Band – <span class="gold-text"> 5:30 p.m.</span>
            </td>
            <td>
              Kristen Warren – <span class="gold-text"> 4:20 p.m.</span>
            </td>
          </tr>
          <tr>
            <td>
              Chuck Ragan & The Camaraderie –{" "}
              <span class="gold-text"> 6:50 p.m.</span>
            </td>
            <td>
              The Lowly Worm – <span class="gold-text"> 5:30 p.m.</span>
            </td>
          </tr>
          <tr>
            <td>
              Kingfish Ingram – <span class="gold-text"> 8:20 p.m.</span>
            </td>
            <td></td>
          </tr>
        </table>
        <h2 class="gold-text">*PLEASE NOTE SET TIMES ARE SUBJECT TO CHANGE</h2>
      </div>
    </div>
  );
};

export default SetSchedule2;
