import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import menuIcon from "../Images/menuIcon.png";
import "../Styles/Menu.css";
import NewACWhiteIcon from "../Images/NewWhiteLogo.png";
import SetSchedule from "../Assets/Updated-event-schedule.pdf";
import finalProclamation from "../Assets/final-proclamation.pdf";

const Menu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const location = useLocation(); // Get current path
  // console.log(location);

  const handleLinkClick = (path) => {
    if (location.pathname === path) {
      setIsMenuOpen(false);
    }
  };

  return (
    <>
      {/* Hamburger Menu Icon */}
      <div className="hamburger-menu" onClick={toggleMenu}>
        <img src={menuIcon} alt="Navigation Menu" className="menu-icon" />
      </div>

      {/* Menu Overlay */}
      <div className={`menu-overlay ${isMenuOpen ? "open" : ""}`}>
        <div className="blue-background" style={{ height: "100px" }}>
          <img
            src={NewACWhiteIcon}
            style={{
              height: "80px",
              alignItems: "flex-start",
              display: "flex",
              paddingTop: "10px",
              paddingLeft: "25px",
            }}
            alt="Discover 200 Icon"
          />
          <button className="close-menu-button" onClick={toggleMenu}>
            &times; {/* "X" icon */}
          </button>
        </div>
        <ul>
          {/* {(() => {
            if (location.pathname !== "/") {
              return (
                <li>
                  <Link to="/">Home</Link>
                </li>
              );
            }
          })()}
          {(() => {
            if (location.pathname !== "/events") {
              return (
                <li>
                  <Link to="/events">Events</Link>
                </li>
              );
            }
          })()} */}

          <li class={location.pathname === "/" ? "active-menu-item" : ""}>
            <Link to="/" onClick={() => handleLinkClick("/")}>
              Home
            </Link>
          </li>
          {/* <li class={location.pathname === "/events" ? "active-menu-item" : ""}>
            <Link to="/events" onClick={() => handleLinkClick("/events")}>
              Events
            </Link>
          </li> */}
          <li>
            <a
              href={SetSchedule}
              target="_blank"
              rel="noreferrer"
              // download="Alachua County History"
            >
              Event set schedule
            </a>
          </li>
          <li>
            <a
              href="https://www.visitgainesville.com/"
              target="_blank"
              rel="noreferrer"
            >
              Visit Gainesville Alachua County
            </a>
          </li>
          <li>
            <a
              href="https://alachuacounty.us/Pages/AlachuaCounty.aspx"
              target="_blank"
              rel="noreferrer"
            >
              Alachua County Home
            </a>
          </li>
          <li>
            <a
              href="https://alachuacounty.us/Depts/parks/Pages/default.aspx"
              target="_blank"
              rel="noreferrer"
            >
              County Parks
            </a>
          </li>
          <li>
            <a
              href="https://truth.alachuacounty.us/"
              target="_blank"
              rel="noreferrer"
            >
              ACCRP: Truth & Reconciliation
            </a>
          </li>
          <li>
            <a
              href={finalProclamation}
              target="_blank"
              rel="noreferrer"
              // download="Alachua County History"
            >
              Discover 200 Proclamation
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/playlist?list=PLTi6BogdiQZ1RuVOia0C1888M6k8Ox7v7"
              target="_blank"
              rel="noreferrer"
            >
              Black History Resources
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Menu;
